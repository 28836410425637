import 'intro.js/introjs.css'
import Head from 'next/head'
import React, { useEffect, useRef } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Hydrate } from '@tanstack/react-query'
import Layout from '../components/layout'
import { AuthProvider } from '../contexts/auth'
import { appWithTranslation } from 'next-i18next'
import { setCustomParameters } from '../utils/set-custom-parameters'
import DataLayerScript from '../components/gtm/datalayer-script'
import TrackingScript from '../components/gtm/tracking-script'
import { FeatureToggleProvider } from '../contexts/feature-toggle'
import ErrorBoundary from '@/components/error/error-boundary'
import { SlideInDrawerProvider } from '@/contexts/slide-in-drawer-context'
import { AlertModalProvider } from '@/contexts/alert-modal-context'
import { PopupModalProvider } from '@/contexts/popup-modal-context'
import '/public/wolseleysearchpage/wolseleysearchpage.css'
import { RegionProvider } from '@/contexts/region-context'

const allLanguages = ['en', 'fr']

const QUERY_CLIENT_OPTIONS = {
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: (error) => {
        if (process.env.NODE_ENV === 'production') {
          console.error(error)
        }
        if (process.env.NODE_ENV === 'development') alert(JSON.stringify(error, null, 2))
      },
    },
  },
}

function EnhancedApp({ Component, pageProps, router }) {
  const queryClientRef = useRef()

  const useCustomHeaderFooter = router?.pathname?.toLowerCase()?.includes('/checkout')

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient(QUERY_CLIENT_OPTIONS)
  }

  const generateAlternateTags = (lang) => {
    const { pathname, asPath } = router
    const href = `${process.env.NEXT_PUBLIC_DOMAIN}/${lang}${pathname === '/' ? '' : asPath}`
    return <link key={lang} rel='alternate' hrefLang={lang} href={href} />
  }

  useEffect(() => {
    if (/iPad|iPhone|iPod/.test(window.navigator.userAgent)) {
      const meta = document.createElement('meta')
      meta.setAttribute('name', 'viewport')
      meta.content = 'width=device-width, initial-scale=1, maximum-scale=1'
      document.getElementsByTagName('head')[0].appendChild(meta)
    }
  }, [])

  useEffect(() => {
    setCustomParameters()
  }, [])

  useEffect(() => {
    // Add global error handler for uncaught promise rejections
    window.addEventListener('unhandledrejection', (event) => {
      console.error('Uncaught promise rejection:', event.reason)
    })
  }, [])

  return (
    <>
      <Head>
        <title>{process.env.NEXT_PUBLIC_APP_TITLE}</title>
        {allLanguages.map(generateAlternateTags)}
        <meta name='viewport' content='width=device-width, initial-scale=1'></meta>
      </Head>
      <QueryClientProvider client={queryClientRef.current}>
        <Hydrate state={pageProps.dehydratedState}>
          <FeatureToggleProvider>
            <AuthProvider initialState={{ token: pageProps.token }}>
              <RegionProvider>
                <ErrorBoundary>
                  <AlertModalProvider>
                    <PopupModalProvider>
                      <SlideInDrawerProvider>
                        <Layout useCustomHeaderFooter={useCustomHeaderFooter} sideNav={pageProps?.sideNav}>
                          <Component {...pageProps} />
                        </Layout>
                      </SlideInDrawerProvider>
                    </PopupModalProvider>
                  </AlertModalProvider>
                </ErrorBoundary>
                <DataLayerScript />
                <TrackingScript />
              </RegionProvider>
            </AuthProvider>
          </FeatureToggleProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  )
}
export default appWithTranslation(EnhancedApp)
