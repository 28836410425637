import PropTypes from 'prop-types'

const colorMap = {
  primary: 'var(--primary-color)',
  secondary: 'var(--secondary-text-color)',
  alert: 'var(--alert-color)',
  success: 'var(--success-color)',
  error: 'var(--error-color)',
  accessible: 'var(--ui-accessible-blue)',
  wolseleyDarkGrey: 'var(--wolseleyDarkGrey)',
}

const TextButton = ({ children, color = 'primary', startIcon, endIcon, ...rest }) => {
  return (
    <button type='button' className='flatBtn' {...rest}>
      {startIcon && <div className='startIcon'>{startIcon}</div>}
      {children}
      {endIcon && <div className='endIcon'>{endIcon}</div>}

      <style jsx>{`
        button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          transition: opacity 0.2s;
          color: ${colorMap[color] || 'var(--primary-color)'};
          font-size: 1.4rem;

          .startIcon {
            display: inherit;
            margin-right: 8px;
            margin-left: -4px;
          }

          .endIcon {
            display: inherit;
            margin-right: -4px;
            margin-left: 5px;
          }
        }

        @media (max-width: 768px) {
          button {
            font-size: 1.2rem;

            .startIcon {
              margin-right: 4px;
              margin-left: -2px;
            }

            .endIcon {
              margin-right: -2px;
              margin-left: 4px;
            }
          }
        }
      `}</style>
    </button>
  )
}

TextButton.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color: PropTypes.oneOfType([PropTypes.oneOf(['primary', 'secondary', 'success', 'error']), PropTypes.string]),
  /**
   * Element placed before the children.
   */
  startIcon: PropTypes.node,
  /**
   * Element placed after the children.
   */
  endIcon: PropTypes.node,
}

export default TextButton
