import { useRef, useEffect, useState, memo, useCallback } from 'react'
import { createPortal } from 'react-dom'
import useClickOutside from '../../hooks/use-clickoutside'
import { zIndex } from '../../constants'
import CloseV2X from '../svg/close-v2-x'
import { useAuth } from '../../contexts/auth'

const Modal = ({
  top,
  closeOnClickOutside = true,
  hideCloseBtn = false,
  name,
  onClose,
  children,
  token,
}) => {
  // Avoid Warning: 'Expected server HTML to contain a matching <div> in <div>.'
  // portal can't be created during SSR
  const [hydrated, setHydrated] = useState(false)
  const modalRef = useRef()
  const mouseDownTarget = useRef()
  const containerRef = useRef()
  const [{}, { logout }] = useAuth()

  const elementBody = document.querySelector('body')
  if (!elementBody.classList.contains('bodyFreeze')) elementBody?.classList?.add('bodyFreeze')

  useEffect(() => {
    containerRef.current = document.createElement('div')

    document.body.appendChild(containerRef.current)
    setHydrated(true)

    return () => {
      document.body.removeChild(containerRef.current)
    }
  }, [])

  const handleClose = useCallback(() => {
    const elementBody = document.querySelector('body')
    elementBody?.classList?.remove('bodyFreeze')

    onClose(name)
  }, [name, onClose])

  useClickOutside(
    modalRef,
    handleClose,
    !closeOnClickOutside || (typeof window !== 'undefined' && window.innerWidth < 786),
    mouseDownTarget,
  )

  const handleMouseDown = (event) => {
    event.persist()
    mouseDownTarget.current = event.target
  }

  const onCloseHandler = () => {
    onClose(name)
    const elementBody = document.querySelector('body')
    elementBody?.classList?.remove('bodyFreeze')
    if (!!token) logout({ token })
  }

  // Uses separate useEffect to prevent re-creation of modal parent
  // on every onClose reference change, even though it's expected to be memorized
  useEffect(() => {
    const escapeListener = (event) => {
      if (event.key === 'Escape') if (closeOnClickOutside) onClose(name)
    }

    document.addEventListener('keydown', escapeListener)

    return () => document.removeEventListener('keydown', escapeListener)
  }, [name, onClose, closeOnClickOutside])

  const Component = (
    <div className='backdrop' onMouseDown={handleMouseDown}>
      <div ref={modalRef} className='modal open' role='dialog' aria-modal='true'>
        <section className='content'>
          {children}
          {!hideCloseBtn && <CloseV2X onClick={() => onCloseHandler()} className='flatBtn close' />}
        </section>
      </div>

      <style jsx>{`
        .backdrop {
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.47);
          z-index: ${zIndex.Modal};
          overflow-x: hidden;
          overflow-y: auto;
        }

        @keyframes slideInFromTop {
          0% {
            top: 0px;
            opacity: 0;
          }
          100% {
            top: ${top ?? '50%'};
            opacity: 1;
          }
        }

        .modal {
          position: absolute;
          left: 50%;
          top: ${top ?? '50%'};
          opacity: 1;
          transform: translate(-50%, ${top ?? '-50%'});
          animation: 0.4s ease-in-out 0s 1 slideInFromTop;

          :global(.close) {
            position: absolute;
            top: 3rem;
            right: 4rem;
            width: 3.4rem;
            height: 3.4rem;
            cursor: pointer;
          }

          :global(.close:hover) {
            fill: var(--wolseleyRed);
          }

          .content {
            position: relative;
            overflow: hidden;
            //max-height: 100vh;
            background: linear-gradient(
              to bottom,
              rgba(230, 230, 230, 1) 0%,
              rgba(255, 255, 255, 1) 100%
            );
          }
        }

        @media (max-width: 768px) {
          .backdrop {
            top: 0;
          }

          .modal {
            right: 0;
            bottom: 0;
            left: 0;
            transform: translate(0%, -50%);
            :global(.close) {
              right: 1.5rem !important;
              top: 2.5rem;
            }
            .content {
              width: 90%;
              max-width: 50rem;
              margin: 0 auto;
            }
          }
        }
      `}</style>
    </div>
  )

  if (!hydrated) return null

  return createPortal(Component, containerRef.current)
}

export default memo(Modal)
