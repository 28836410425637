import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { filterMenuByPermissions } from '@/utils/menu-permission-filter'
import { getMenuSelection } from '@/utils/get-selected-menuitem-details'
import { LayoutSources, ROUTES } from '@/constants'
import { MyAccountConstants } from '@/containers/myaccount/constants'
import { useAuth } from '@/contexts/auth'
import useDeviceDetect from '@/hooks/useDeviceDetect'
import Breadcrumbs from '../breadcrumbs'
import SideNav from '../side-nav'
import classNames from 'classnames'

const TwoColumnLayout = ({ sideNav, children }) => {
  const router = useRouter()
  const { deviceTypeBasedOnWidth } = useDeviceDetect()
  const [{ permissions }] = useAuth()
  const { t } = useTranslation(['sidenav'])

  const [menu, setMenu] = useState([])
  const [selectedMenuItem, setSelectedMenuItem] = useState({})
  const [sideNavExpanded, setSideNavExpanded] = useState(true)
  const showBreadcrumb = !!!sideNav?.excludeBreadcrumbs && selectedMenuItem?.breadcrumb

  const getMenuItems = () => {
    switch (sideNav?.source) {
      case LayoutSources.myAccount:
        return filterMenuByPermissions(MyAccountConstants.menu, permissions)
      default:
        return []
    }
  }

  const toggleSideNavExpanded = () => {
    setSideNavExpanded((currVal) => !currVal)
  }

  useEffect(() => {
    if (sideNav?.source && !!permissions) {
      const menuItems = getMenuItems().map((menuItem) => {
        // Translate title for l1
        const updatedL1 = { ...menuItem.l1, title: t(menuItem.l1.title) }

        // Translate titles for l2 if present
        if (updatedL1.l2 && Array.isArray(updatedL1.l2)) {
          updatedL1.l2 = updatedL1.l2.map((l2Item) => ({
            ...l2Item,
            title: t(l2Item.title),
          }))
        }

        return { l1: updatedL1 }
      })

      setMenu(menuItems)
      setSelectedMenuItem(getMenuSelection(menuItems, router?.pathname?.toLowerCase()))
    }
  }, [permissions])

  useEffect(() => {
    setSideNavExpanded(deviceTypeBasedOnWidth.desktop)
  }, [deviceTypeBasedOnWidth])
  return (
    <>
      <div className={classNames('main-content flex', { flexDirectionRow: !deviceTypeBasedOnWidth.mobile, flexDirectionColumn: deviceTypeBasedOnWidth.mobile })}>
        {deviceTypeBasedOnWidth.mobile && showBreadcrumb && <Breadcrumbs breadcrumbData={[{ text: t('wca.home'), path: ROUTES.Home }, ...(selectedMenuItem?.breadcrumb ?? [])]} />}
        {!!sideNav?.source && <SideNav expanded={sideNavExpanded} menuItems={menu} toggleExpanded={toggleSideNavExpanded} />}
        <div className='content flex flexDirectionColumn'>
          {!deviceTypeBasedOnWidth.mobile && showBreadcrumb && <Breadcrumbs breadcrumbData={[{ text: t('wca.home'), path: ROUTES.Home }, ...(selectedMenuItem?.breadcrumb ?? [])]} />}
          <div className='wrapper'>
            <main>{children}</main>
          </div>
        </div>
      </div>

      <style jsx global>{`
        .mainWrapper {
          width: 100%;
          max-width: 1280px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;

          .main-content {
            padding: 2rem 0 5rem 0;
            gap: ${sideNavExpanded ? '3rem' : '9.7rem'};

            .content {
              width: 100%;
              max-width: min(calc(100% - ${sideNavExpanded ? 30.1 : 14.5}rem), 94.9rem);
              .wrapper {
                width: 100%;
                overflow-x: auto;

                main {
                  min-height: 50vh;
                  width: 94.9rem;
                  border: 1px solid black;
                }
              }
            }
          }
        }

        @media (max-width: 1440px) and (min-width: 1281px), (max-width: 1280px) and (min-width: 1025px) {
          .mainWrapper {
            .main-content {
              justify-content: flex-start;
              gap: ${sideNavExpanded ? '2rem' : '9.8rem'};
              .content {
                max-width: min(calc(100% - ${sideNavExpanded ? 29.1 : 14.6}rem), 94.9rem);
              }
            }
          }
        }

        @media (max-width: 1280px) and (min-width: 1025px) {
          .mainWrapper {
            .main-content {
              margin: 0 2rem;
            }
          }
        }

        @media (max-width: 1024px) and (min-width: 768px) {
          .mainWrapper {
            .main-content {
              gap: 2rem;
              margin: 0 1rem;
              .content {
                max-width: calc(100% - 6.8rem);
              }
            }
          }
        }

        @media (max-width: 767px) {
          .mainWrapper {
            .main-content {
              margin: 0 1rem;
              gap: 1rem;
              .side-nav {
                width: 100%;
              }

              .content {
                max-width: unset;
                .wrapper {
                  main {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(TwoColumnLayout), {
  ssr: false,
})
