export const getMenuSelection = (menu, route) => {
  const findItemAndBuildBreadcrumb = (menuItems, currentRoute, parentBreadcrumb = []) => {
    return menuItems.reduce((result, menuItem) => {
      if (result) return result // If item found, skip the rest

      const { l1 } = menuItem

      // Handle level 1 and move to level 2
      if (l1.l2) {
        for (const l2Item of l1.l2) {
          let currentBreadcrumb = [...parentBreadcrumb]

          // Add l2 item to breadcrumb if it has a route
          if (l2Item.route && currentRoute?.endsWith(l2Item.route.toLowerCase())) {
            if (l1.route) {
              currentBreadcrumb.push({ text: l1.title, path: l1.route })
            }
            currentBreadcrumb.push({ text: l2Item.title, path: l2Item.route })
            return { id: l2Item.id, breadcrumb: currentBreadcrumb }
          }
        }
      }

      return null // Continue if no match found
    }, null)
  }

  return !!route && menu?.length > 0 ? findItemAndBuildBreadcrumb(menu, route) : {}
}
