import { useRouter } from 'next/router'
import classNames from 'classnames'
import CookieTermsAndConditions from '../../containers/cookie-terms-and-conditions'
import LiveChat from '@/utils/live-chat'
import getBackgroundColor from '@/utils/get-bg-color'
import useLanuageChange from '@/hooks/use-language-change'
import Header from '../header'
import TopRibbon from '../header/top-ribbon'
import AdminHeaderLink from '../header/admin-header-link'
import CustomHeader from '../header/custom-header'
import Footer from '../footer'
import CustomFooter from '../footer/custom-footer'
import TwoColumnLayout from '../two-column-layout'
import SessionTimeoutModal from '../session-timeout/session-timeout-modal'
import WarningBannerTopic from '../header/warning-banner-topic'
import DynamicYieldAdd2Cart from '../dynamic-yield-add2cart/dynamic-yield-add2cart'

export default function Layout({ children, useCustomHeaderFooter = false, sideNav = {} }) {
  const router = useRouter()
  useLanuageChange()

  const bgColor = getBackgroundColor(router?.pathname)

  return (
    <>
      {!useCustomHeaderFooter && <AdminHeaderLink />}
      {!useCustomHeaderFooter && <WarningBannerTopic />}
      {!useCustomHeaderFooter && <TopRibbon />}
      {useCustomHeaderFooter && <CustomHeader />}
      <div className={classNames('mainWrapper', { customWrapper: useCustomHeaderFooter })}>
        {!useCustomHeaderFooter && <Header />}
        <div id='backdrop'></div>
        {!!sideNav.source ? <TwoColumnLayout sideNav={sideNav}>{children}</TwoColumnLayout> : <main>{children}</main>}
        {!useCustomHeaderFooter && <Footer />}
      </div>
      {useCustomHeaderFooter && <CustomFooter />}
      <CookieTermsAndConditions />
      {!useCustomHeaderFooter && <LiveChat />}
      <SessionTimeoutModal />
      <DynamicYieldAdd2Cart />

      <style jsx global>{`
        :root {
          --wolseleyRed: #cd2e31;
          --wolseleyGrey: #656565;
          --wolseleyDarkGrey: #3b3b3b;

          --primary-color: #cd2e31;
          --alert-color: #cd2e31;
          --success-color: #009211;
          --error-color: #cd2e31;
          --text-link-color: var(--primary-color);
          --body-bg: rgba(233, 236, 242, 0.47);

          --primary-text-color: #3b3b3b;
          --secondary-text-color: var(--wolseleyGrey);

          --primary-button-color: #fff;
          --primary-button-bg: var(--primary-color);
          --secondary-button-bg: var(--wolseleyGrey);

          --primary-input-bg: #fff;
          --secondary-input-bg: #e9ecf2;

          --separator-line-color: #cdd1e6;
          --ui-accessible-blue: #1362d0;
          --dark-green: #006624;
          --title-blue: #0d64b7;
          --state-error: #ce3f00;
        }

        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          outline: none;
          list-style: none;
          border: 0;
        }

        div,
        img,
        a,
        ul,
        li,
        span,
        h1,
        h2,
        h3,
        h4,
        h5 {
          padding: 0 0 0 0;
          margin: 0 0 0 0;
          list-style: none;
          border: 0;
          outline: none !important;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 500;
        }

        a {
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }

        html,
        body {
          font-size: 10px;
          font-family: Arial;
          color: var(--primary-text-color);
          background-color: #ffffff;
        }

        .mainWrapper {
          width: 100%;
          max-width: 1280px;
          margin: 0 auto;

          main {
            min-height: 50vh;
            padding: 2rem 0 5rem 0;
            background-color: ${bgColor};
          }
        }

        .customWrapper {
          max-width: 90.7rem;
        }

        .flex {
          display: flex;
        }
        .inlineFlex {
          display: inline-flex;
        }
        .flexDirectionColumn {
          flex-direction: column;
        }
        .flexDirectionRow {
          flex-direction: row;
        }
        .flexWrap {
          flex-wrap: wrap;
        }
        .flexWrapNone {
          flex-wrap: none;
        }
        .alignCenter {
          align-items: center;
        }
        .alignStretch {
          align-self: stretch;
        }
        .alignItemsStretch {
          align-items: stretch;
        }
        .alignStart {
          align-items: flex-start;
        }
        .alignSelfStart {
          align-self: flex-start;
        }
        .alignEnd {
          align-items: flex-end;
        }
        .alignSelfEnd {
          align-self: flex-end;
        }
        .justifyBetween {
          justify-content: space-between;
        }
        .justifyAround {
          justify-content: space-around;
        }
        .justifyCenter {
          justify-content: center;
        }
        .justifyStart {
          justify-content: flex-start;
        }
        .justifyEnd {
          justify-content: flex-end;
        }
        .justifySpaceEvenly {
          justify-content: space-evenly;
        }
        .justifyItemsCenter {
          justify-items: center;
        }
        .justifySelfStart {
          justify-self: flex-start;
        }
        .justifySelfCenter {
          justify-self: center;
        }

        .absoluteCenter {
          position: relative;

          > * {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .d-none {
          display: none;
        }

        .d-grid {
          display: grid;
        }

        .w-100 {
          width: 100%;
        }

        .cursor-pointer {
          &:not(:disabled) {
            cursor: pointer;
          }
        }

        .text-bold {
          font-weight: 700;
        }

        .wolseley-btn-primary {
          padding: 12px 15px;
          border-radius: 6px;
          background: var(--wolseleyRed);
          color: var(--primary-button-color);
          font-size: 14px;
          font-weight: 400;
          line-height: 16.1px;
          text-align: center;
          opacity: unset;
          cursor: pointer;

          &:hover:not(:disabled, .disabled, :active) {
            box-shadow: 0px 0px 0px 3px #cd2e3180;
            opacity: unset;
            text-decoration: none;
          }

          &:active:not(:disabled, .disabled) {
            background: #a80003;
            opacity: unset;
            text-decoration: none;
          }

          &:disabled,
          &.disabled {
            background: #00000026;
            border: 1px solid #00000040;
            color: #00000040;
            opacity: unset;
          }
        }

        fieldset {
          border: 0;
        }

        input,
        textarea {
          display: block;
          appearance: none;
          font-family: inherit;

          &:read-only,
          &:disabled {
            &:not([type='checkbox']):not([type='radio']) {
              background: var(--secondary-input-bg) !important;
              cursor: not-allowed;
            }
          }
        }

        button {
          border: 0;
          cursor: pointer;
          font: inherit;

          &.flatBtn {
            background: none;
          }

          &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }

          &:active {
            opacity: 0.9;
          }
        }

        .btn-primary {
          display: inline-block;
          padding: 1.5rem;
          color: #fff;
          border-radius: 0.6rem;
          font-size: 1.4rem;
          background: var(--primary-button-bg);
          width: 100%;
          font-weight: 500;
          text-align: center;
          white-space: nowrap;
          cursor: pointer;
        }

        a {
          text-decoration: none;
          color: inherit;
        }

        li {
          list-style: none;
        }

        .backdrop-menu {
          width: 100vw;
          height: 100vh;
          position: absolute;
          background-color: black;
          z-index: 101;
          opacity: 0.7;
          backdrop-filter: blur(5px);
          left: 0;
        }

        body {
          &.bodyFreeze {
            overflow: hidden !important;
          }
        }

        .introjs-overlay {
          z-index: 99999997;
        }

        .introjs-helperLayer {
          z-index: 99999998;
        }

        .introjs-disableInteraction {
          z-index: 99999999 !important;
        }

        .introjs-tooltip-title {
          color: #000;
          font-family: Arial;
          font-size: 1.8rem;
          font-style: normal;
          font-weight: 700;
        }

        .introjs-tooltiptext {
          color: #000;
          font-size: 1.4rem;
          font-family: Arial;
        }

        .introjs-button {
          display: flex;
          width: 4.1rem;
          padding: 0.8rem 1.5rem;
          justify-content: center;
          align-items: center;
          gap: 1rem;

          border-radius: 0.3rem;
          border: 1px solid #616161;
          background: #f4f4f4;

          color: #212121;
          text-align: center;
          font-family: Arial;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.4rem;

          &.introjs-disabled {
            border: 1px solid #bdbdbd;
            background: #f4f4f4;
            color: #9e9e9e;
          }

          &.introjs-donebutton:hover,
          &.introjs-nextbutton:hover {
            background-color: #e0e0e0;
          }
        }

        .introjs-button:focus {
          outline: 0;
          text-decoration: none;
          background-color: #f4f4f4;
          box-shadow: none;
        }

        .introjs-dontShowAgain {
          display: flex;
          align-items: center;
        }

        .introjs-dontShowAgain input {
          appearance: auto;
          width: 12px;
          height: 12px;
          margin-bottom: 0;

          :read-only {
            cursor: pointer !important;
          }
        }

        .forcedCenter {
          margin: 0 auto;
          left: 50% !important;
        }

        .introjs-tooltip {
          &.availabilityModalToolTip {
            top: 28%;
          }
        }

        @media (max-width: 768px) {
          html {
            scroll-padding-top: 5.5rem;
          }

          button {
            outline: none;
          }

          .introjs-tooltip {
            &.saveToListToolTip {
              left: -197px !important;
              .introjs-arrow.bottom-middle {
                left: 90% !important;
              }
              .introjs-arrow.top-middle {
                left: 88% !important;
              }
            }
            &.forcedCenter {
              left: 15% !important;
            }
          }
        }

        .visible-lg,
        .visible-md,
        .visible-sm,
        .visible-xs {
          display: none !important;
        }
        .img-responsive {
          border-radius: 7px;
          width: 100%;
        }

        @media (max-width: 767px) {
          .visible-xs {
            display: block !important;
            width: 100%;
          }
        }

        @media (max-width: 768px) {
          .visible-sm {
            display: block !important;
          }
        }

        @media (min-width: 769px) and (max-width: 1199px) {
          .visible-md {
            display: block !important;
          }
        }

        @media (min-width: 1200px) {
          .visible-lg {
            display: block !important;
          }
        }
        @media (max-width: 430px) {
          .visible-xs {
            display: block !important;
            width: 100%;
          }
        }
        @media (max-width: 359px) {
          .flexDirectionColumnMobile {
            flex-direction: column;
          }
        }
      `}</style>
    </>
  )
}
