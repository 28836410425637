import { CommonPermissionsMap, ROUTES } from '@/constants'

export const MyAccountConstants = {
  menuIcons: {
    accountOverview: 'AccountOverview',
    orders: 'Orders',
  },
  menuItemType: {
    link: 'Link',
    text: 'Text',
  },
}

MyAccountConstants.menu = [
  {
    l1: {
      id: 'L1_0',
      title: 'wca.accountoverview',
      icon: MyAccountConstants.menuIcons.accountOverview,
      l2: [
        {
          id: 'L2_0_0',
          title: 'wca.myaccountdashboard',
          type: MyAccountConstants.menuItemType.text,
          route: ROUTES.MyWolseley,
        },
      ],
    },
  },
  {
    l1: {
      id: 'L1_1',
      title: 'wca.orders',
      icon: MyAccountConstants.menuIcons.orders,
      l2: [
        {
          id: 'L2_1_0',
          title: 'wca.myorders',
          type: MyAccountConstants.menuItemType.text,
          route: ROUTES.MyOrders,
          permissions: [CommonPermissionsMap.orderStatus],
        },
        {
          id: 'L2_1_1',
          title: 'wca.mydraftorders',
          type: MyAccountConstants.menuItemType.text,
          route: ROUTES.Checkout,
          permissions: [CommonPermissionsMap.orderStatus],
        },
      ],
    },
  },
]
