import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { useTranslation } from 'react-i18next'
import useDeviceDetect from '@/hooks/useDeviceDetect'

const SideNav = ({ expanded, menuItems, toggleExpanded }) => {
  const { t } = useTranslation('sideNav')
  const { deviceTypeBasedOnWidth } = useDeviceDetect()

  const renderMenuItems = (item) => {
    const navigateToMenu = () => {
      window.location.href = item.route
    }

    return (
      <li key={item.id} onClick={navigateToMenu}>
        {t(item.title)}
      </li>
    )
  }

  const renderMainMenu = (item) => {
    const { id, title, l2 = [] } = item.l1

    return (
      <li key={id}>
        {t(title)}
        {l2.length && <ul>{l2.map((subMenuItem) => renderMenuItems(subMenuItem))}</ul>}
      </li>
    )
  }

  return (
    <>
      <div className={classNames('side-nav flex flexDirectionColumn', { expanded: expanded })}>
        {deviceTypeBasedOnWidth.desktop && <button onClick={toggleExpanded}>{`<>`}</button>}
        <ul>{menuItems.map((menuItem) => renderMainMenu(menuItem))}</ul>
      </div>
      <style jsx>{`
        .side-nav {
          width: 4.8rem;
          border: 1px solid black;

          &.expanded {
            width: 27.1rem;
          }
        }
      `}</style>
    </>
  )
}

export default dynamic(() => Promise.resolve(SideNav), { ssr: false })
